
.about h1 {
    font-size: calc(2vw + 2vh);
    margin: 20px;
    text-align: center;
}

.about img {
    margin: 10px;
    float: left;
    width: 40vw;
}

.about p {
    margin: 1vw 3vw 10vw;
    text-align: left;
    line-height: min(4vh, 4.5vw);
    font-size: calc(1vw + 1vh);
}