
.article {
}

h1 {
    font-size: 8vw;
    text-align: center;
}

img {
    padding: 1.5vw;
    width: 30vw;
    height: 30vw;
    object-fit: contain;
    float: left;
}

p {
    margin: 1vw 1.5vw 10vw;
    text-align: left;
    line-height: min(3.2vh, 4.5vw);
    font-size: calc(1vw + 1vh);
}

