.header {
    margin: 5px;
    width: calc(100vw - 14px);
    height: 4vh;
    border-style: solid;
    border-width: .2vw;
    border-color: #2ed0a5;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.header .link {
    text-decoration: none;
    border-width: 0;
    background: transparent;
    color: #2ed0a5;
    font-size: min(2.5vh, 5vw);
}

.main {
    margin: 0 5px;
    width: calc(100vw - 14px);
    min-height: calc(96vh - 23px);
    border-style: solid;
    border-width: .2vw;
    border-color: #2ed0a5;
    display: inline-block;
}
