
.project {
    color: white;
    text-decoration: none;
}

.projects {
    text-align: center;
    padding: 10px 0;
    line-height: 4vw;
    font-size: 80%;
}

.projects h1 {
    font-size: calc(min(6vw, 4.5vh));
}

.projects .entry {
    display: flex;
    margin: 20px 2vw;
    justify-content: space-between;
}

.projects .entry.odd {
    flex-direction: row-reverse;
}

.projects .entry.even {
    flex-direction: row;
}

.projects .entry .blurt {
    width: calc(max(45vw, 92vw - 30vh));
    height: calc(min(45vw, 30vh));
    word-wrap: anywhere;
    text-align: left;
    font-size: calc(min(3vw, 3.5vh));
}

.projects .entry img {
    width: calc(min(45vw, 30vh));
    height: calc(min(45vw, 30vh));
    object-fit: contain;
}