.home {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100vw - 14px);
    height: calc(96vh - 28px);
}

.home div {
    width: 50%;
    max-height: calc(max(60vw - 14px, 48vh - 28px));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home .gif {
    width: calc(100% - 5px);
    height: calc(100% - 5px);
}
.home .gif img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.home .typing {
    margin: 10px;
    font-size: calc((50vw - 35px) / (var(--steps) + 2));
}


@media (orientation: portrait) {
    .home {
        flex-direction: column-reverse;
    }
    .home div {
        width: 100%;
    }
    .home .typing {
        font-size: calc((90vw - 25px) / (var(--steps) + 2));
    }
}




.typing {
    --speed: 300ms;
    --delay: 200ms;
    --steps: 12;
    --blinkTime: 500ms;
    position: relative;
    width: max-content;
    line-height: normal;
}

.typing::before, .typing::after {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
}

.typing::before {
    content: '';
    background: black;
    animation: typing var(--speed) steps(var(--steps)) var(--delay) forwards;
}
.typing::after {
    opacity: 0;
    content: '_';
    animation:
            typing var(--speed) steps(var(--steps)) var(--delay) forwards,
            blink var(--blinkTime) step-start var(--delay) 2;
}

@keyframes typing {
    to {
        left: 100%
    }
}
@keyframes blink {
    50% {
        opacity: 1;
    }
}
