
.notfound {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.notfound img {
    display: block;
    margin:  0 auto;
    height: calc(min(85vw, 75vh));
    width: calc(min(85vw, 75vh));
    object-fit: contain;
}

.notfound h1 {
    text-align: center;
    font-family: 'PressStart', monospace;
    font-size: calc((100vw - 50px) / 18);
    color: #2ed0a5;
    margin: 20px 10px;
}