@font-face {
  font-family: "PressStart";
  src: url("press-start.k.ttf") format("truetype");
}

body {
  font-family: 'PressStart', monospace;
  background: black;
  color: white;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #2ed0a5;
}